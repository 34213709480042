@font-face {
  font-family: 'FS Emeric Regular';
  src: url('../fonts/FSEmeric-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FS Emeric Thin';
  src: url('../fonts/FSEmeric-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FS Emeric Medium';
  src: url('../fonts/FSEmeric-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FS Emeric Core';
  src: url('../fonts/FSEmeric-Core.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FS Emeric Bold';
  src: url('../fonts/FSEmeric-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FS Emeric Light';
  src: url('../fonts/FSEmeric-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FS Emeric SemiBold';
  src: url('../fonts/FSEmeric-SemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}